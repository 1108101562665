/* eslint-disable no-lonely-if */
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  FieldErrorsImpl,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  schema,
  schemaFinanceiro,
  schemaLog,
  schemaModal,
  schemaModalAjuste,
  schemaTransporte,
} from './validations';

import { toast } from 'react-toastify';
import { PerfilProps } from './protocols/PerfilProps';
import {
  SelectCFOPSProps,
  SelectProps,
  SelectModalProps,
  SelectCondicaoProps,
} from './protocols/SelectProps';
import { FieldValues, UseFormRegister, Control } from 'react-hook-form';
import { FornecedorProps } from './protocols/FornecedorProps';
import api from '~/services/api';

import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { defineCfopOutrasOperacoes } from './functions/auxiliares/defineCfopOutrasOperacoes';
import { calculaValorIpi } from './functions/auxiliares/calculaValorIpi';
import { calculaTabFinal } from './functions/auxiliares/calculaTabFinal';
import { calculaReducao } from './functions/auxiliares/calculaReducao';
import { calculaIcms } from './functions/auxiliares/calculaIcms';
import { TruncaFrac } from './functions/auxiliares/TruncaFrac';

import { LojaProps, lojaDefault } from './protocols/LojaProps';
import { ProdutosProps } from './protocols/ProdutoProps';
import { ItensAjusteProps } from './protocols/ItensAjusteProps';
import { ItensProps } from './protocols/ItensProps';
import { defineTributacaoOutrasOperacoes } from './functions/auxiliares/defineTributacaoOutrasOperacoes';
import { calculaValTabelaLiq } from './functions/auxiliares/calculaValTabelaLiq';
import { ItensFinaceiroProps } from './protocols/ItensFinaceiroProps';
import Swal from 'sweetalert2';
import getCondicaoPessoaFornecedor from './services/getCondicaoPessoaFornecedor';
import { procedureCalculaPISCofins } from './functions/procedures/procedureCalculaPISCofins';
import { LojaContext } from '~/context/loja';
import getParcelasFinanceiro from './services/getParcelasFinanceiro';
import { procedureValidationItem } from './functions/procedures/procedureValidationItem';
import { LogProps } from './protocols/LogProps';
import getItens from './services/getItens';
import moment from 'moment';
import procedureCalculaItensXml from './functions/procedures/procedureCalculaItensXml';
import { CFOPSProps } from './protocols/CFOPSProps';
import { NfProps } from '~/components/BuscaNfe/protocols/BuscaNfeContextProviderProps';
import { nanoid } from 'nanoid';
import { getUserData } from '~/services/user';
import { Context } from '~/components/Nfe/TransmissaoNFe/Transmissao';
import { isBefore, parseISO, startOfDay } from 'date-fns';

interface NFOutrasOperacoesContextData {
  showSearch: boolean;
  setShowSearch: (showSearch: boolean) => void;
  update: boolean;
  setUpdate: (update: boolean) => void;
  trasmissao: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setTransmissao: (trasmissao: boolean) => void;
  codSeqNf: number | undefined;
  setCodSeqNf: (codSeqNf: number | undefined) => void;
  setNumChaveAcesso: (setNumChaveAcesso: string) => void;
  onSubmit: () => void;
  onClearForm: () => void;
  onCancel: () => void;
  isOpenModalNovoItem: boolean;
  setIsOpenModalNovoItem: (type: boolean) => void;
  OpenModal: () => void;
  tributacaoNovoItem: SelectModalProps | undefined;
  setTributacaoNovoItem: (item: SelectModalProps | undefined) => void;
  cfopNovoItem: SelectModalProps | undefined;
  setCfopNovoItem: (item: SelectModalProps | undefined) => void;
  ncmNovoItem: SelectModalProps | undefined;
  setNcmNovoItem: (item: SelectModalProps | undefined) => void;
  resetModalItem: () => void;
  setItens: (itens: ItensProps[]) => void;
  setIsBlockNf: (isBlockNf: boolean) => void;

  isOpenModalImportAjuste: boolean;
  setIsOpenModalImportAjuste: (type: boolean) => void;
  OpenModalAjuste: () => void;

  currentTab: string;
  setCurrentTab: (item: string) => void;
  fornecedor: FornecedorProps;
  setFornecedor: (item: FornecedorProps) => void;
  fornecedores: SelectProps[];
  setFornecedores: (item: SelectProps[]) => void;
  perfis: SelectProps[];
  setPerfis: (perfis: SelectProps[]) => void;
  perfil: PerfilProps;
  setPerfil: (item: PerfilProps) => void;
  loja: LojaProps;
  setLoja: (loja: LojaProps) => void;
  flg_nfe_pedente: boolean;
  setFlg_nfe_pedente: (flg_nfe_pedente: boolean) => void;
  dtaEmissao: string;
  setDtaEmissao: (dtaEmissao: string) => void;
  dtaEntrada: string;
  setDtaEntrada: (dtaEmissao: string) => void;
  disable: boolean;
  setDisable: (item: boolean) => void;
  buscarProduto: (codProduto: number) => void;
  produto: ProdutosProps;
  /* Base NF * */
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrorsImpl<{ [x: string]: any }>;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  /* Modal * */
  formStateModal: FormState<FieldValues>;
  controlModal: Control<FieldValues, any>;
  registerModal: UseFormRegister<FieldValues>;
  setValueModal: UseFormSetValue<FieldValues>;
  resetModal: UseFormReset<FieldValues>;
  handleSubmitModal: UseFormHandleSubmit<FieldValues>;
  /* Modal Ajuste* */
  formStateModalAjuste: FormState<FieldValues>;
  controlModalAjuste: Control<FieldValues, any>;
  registerModalAjuste: UseFormRegister<FieldValues>;
  setValueModalAjuste: UseFormSetValue<FieldValues>;
  resetModalAjuste: UseFormReset<FieldValues>;
  checkAllItens: (marcarTodos: boolean) => void;
  checkItem: (id: number, marcar: boolean) => void;
  /*  Tranporte */
  formStateTransporte: FormState<FieldValues>;
  controlTransporte: Control<FieldValues, any>;
  registerTransporte: UseFormRegister<FieldValues>;
  setValueTransporte: UseFormSetValue<FieldValues>;
  resetTransporte: UseFormReset<FieldValues>;
  /*  Financeiro */
  formStateFinanceiro: FormState<FieldValues>;
  controlFinanceiro: Control<FieldValues, any>;
  registerFinanceiro: UseFormRegister<FieldValues>;
  setValueFinanceiro: UseFormSetValue<FieldValues>;
  resetFinanceiro: UseFormReset<FieldValues>;
  getValueFinanceiro: any;
  watchFinanceiro: any;
  /*  Log */
  formStateLog: FormState<FieldValues>;
  controlLog: Control<FieldValues, any>;
  registerLog: UseFormRegister<FieldValues>;
  setValueLog: UseFormSetValue<FieldValues>;
  resetLog: UseFormReset<FieldValues>;

  tributacoes: SelectProps[];
  setTributacoes: (trib: SelectProps[]) => void;
  ncms: SelectProps[];
  setNcms: (ncm: SelectProps[]) => void;
  serieSelect: string;
  setSerieSelect: (item: string) => void;
  serieList: SelectProps[];
  setSeriesList: (ncm: SelectProps[]) => void;
  especieList: SelectProps[];
  setEspecieList: (especieList: SelectProps[]) => void;
  cfops: SelectCFOPSProps[];
  setCfops: (trib: SelectCFOPSProps[]) => void;
  logs: LogProps[];
  setLogs: (log: LogProps[]) => void;
  cod_xml: number | undefined;

  tipo_ajustes: SelectProps[];
  setTipo_ajustes: (trib: SelectProps[]) => void;
  init: boolean;
  setInit: (init: boolean) => void;
  itensAjuste: ItensAjusteProps[];
  setItensAjuste: (itens: ItensAjusteProps[]) => void;
  getProdutos: () => void;
  handleSubmitModalAjuste: () => void;
  loadingAjuste: boolean;
  setLoadingAjuste: (item: boolean) => void;
  total: {
    Total_Tab: number;
    Total_IPI: number;
    Credito_ICMS: number;
    Total_Nota_Fiscal: number;
  };
  setTotal: (item: {
    Total_Tab: number;
    Total_IPI: number;
    Credito_ICMS: number;
    Total_Nota_Fiscal: number;
  }) => void;

  onSubmitModal: () => void;
  itens: ItensProps[];
  updateItemModal: boolean;
  deleteItem: (id: number) => void;
  updateItem: (item: any, num_item: number) => void;

  finalizadoras: SelectProps[];
  setFinalizadoras: (finalizadoras: SelectProps[]) => void;
  condicoes: SelectProps[];
  setCondicoes: (condicoes: SelectProps[]) => void;
  itensFinaceiro: ItensFinaceiroProps[];
  onClearFinaceiro: () => void;
  addItemFinaceiro: () => void;
  deleteItemFinaceiro: (id: number) => void;
  updateItemFinaceiro: (item: ItensFinaceiroProps, num_item: number) => void;
  updateFinaceiro: boolean;
  financeiroCalcular: () => void;
  codCondicao: SelectCondicaoProps;
  setCodCondicao: (codCondicao: SelectCondicaoProps) => void;
  numCondicao: number | undefined;
  setNumCondicao: (numCondicao: number | undefined) => void;
  numNF: string;
  setNumNF: (item: string) => void;
  importXML: (data: any) => void;
  isBlockNf: boolean;
  formatPerfil: any;
  setItensFinaceiro: any;
  FocusFilter: (campo: string) => void;
  clearErrors: UseFormClearErrors<FieldValues>;
}

interface ProviderNFOutrasOperacoesProps {
  children: ReactNode;
}

export const NFOutrasOperacoesContext = createContext(
  {} as NFOutrasOperacoesContextData,
);

export function NFOutrasOperacoesContextProvider({
  children,
}: ProviderNFOutrasOperacoesProps) {
  const { transmitirNFe } = useContext(Context);
  const parametroLoja = useContext(LojaContext);
  const [update, setUpdate] = useState<boolean>(false);
  const [trasmissao, setTransmissao] = useState<boolean>(false);
  const [codSeqNf, setCodSeqNf] = useState<number | undefined>(undefined);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModalNovoItem, setIsOpenModalNovoItem] =
    useState<boolean>(false);
  const [tributacaoNovoItem, setTributacaoNovoItem] = useState<
    SelectModalProps | undefined
  >({ value: undefined, label: '' });
  const [ncmNovoItem, setNcmNovoItem] = useState<SelectModalProps | undefined>({
    value: undefined,
    label: '',
  });
  const [cfopNovoItem, setCfopNovoItem] = useState<
    SelectModalProps | undefined
  >({
    value: undefined,
    label: '',
  });

  const [isOpenModalImportAjuste, setIsOpenModalImportAjuste] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('nfe');
  const [numNF, setNumNF] = useState<string>('');
  const [updateItemModal, setUpdateItemModal] = useState<boolean>(false);
  const [numItem, setNumItem] = useState<number | undefined>(undefined);
  const [produto, setProduto] = useState({} as ProdutosProps);
  const [loja, setLoja] = useState<LojaProps>(lojaDefault);
  const [tributacoes, setTributacoes] = useState<SelectProps[]>([]);
  const [ncms, setNcms] = useState<SelectProps[]>([]);
  const [cfops, setCfops] = useState<SelectCFOPSProps[]>([]);
  const [serieSelect, setSerieSelect] = useState<string>('');
  const [serieList, setSeriesList] = useState<SelectProps[]>([]);
  const [especieList, setEspecieList] = useState<SelectProps[]>([]);
  const [flg_nfe_pedente, setFlg_nfe_pedente] = useState<boolean>(false);
  const [dtaEmissao, setDtaEmissao] = useState<string>('');
  const [dtaEntrada, setDtaEntrada] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);

  const [tipo_ajustes, setTipo_ajustes] = useState<SelectProps[]>([]);
  const [fornecedor, setFornecedor] = useState({} as any);
  const [fornecedores, setFornecedores] = useState<SelectProps[]>([]);
  const [perfil, setPerfil] = useState({} as PerfilProps);
  const [perfis, setPerfis] = useState<SelectProps[]>([]);
  const [itens, setItens] = useState<ItensProps[]>([]);
  const [itensAjuste, setItensAjuste] = useState<ItensAjusteProps[]>([]);
  const [loadingAjuste, setLoadingAjuste] = useState<boolean>(false);
  const [itensAjustesRelacionados, setItensAjustesRelacionados] = useState<
    any[]
  >([]);
  const [total, setTotal] = useState({
    Total_Tab: 0,
    Total_IPI: 0,
    Credito_ICMS: 0,
    Total_Nota_Fiscal: 0,
  });
  const [finalizadoras, setFinalizadoras] = useState<SelectProps[]>([]);
  const [condicoes, setCondicoes] = useState<SelectProps[]>([]);
  const [itensFinaceiro, setItensFinaceiro] = useState<ItensFinaceiroProps[]>(
    [],
  );
  const [updateFinaceiro, setUpdateFinaceiro] = useState<boolean>(false);
  const [numItemFinaceiro, setNumItemFinaceiro] = useState<number | undefined>(
    undefined,
  );
  const [codCondicao, setCodCondicao] = useState<SelectCondicaoProps>({
    label: '',
    value: undefined,
  });
  const [numCondicao, setNumCondicao] = useState<number | undefined>(undefined);

  const [logs, setLogs] = useState<LogProps[]>([]);
  const [cod_xml, setCod_xml] = useState<number | undefined>();
  const [isBlockNf, setIsBlockNf] = useState<boolean>(false);
  const [numChaveAcesso, setNumChaveAcesso] = useState<string>('');
  const user = getUserData();
  /* Base NF * */
  const {
    register,
    handleSubmit: OpenModalNovoItem,
    control,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /* Modal * */
  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    control: controlModal,
    reset: resetModal,
    setValue: setValueModal,
    setError: setErrorModal,
    formState: formStateModal,
    clearErrors: clearErrorsModal,
  } = useForm({
    resolver: yupResolver(schemaModal),
    reValidateMode: 'onBlur',
  });
  /* Modal Ajuste */
  const {
    register: registerModalAjuste,
    handleSubmit: buscarProdutoAjuste,
    control: controlModalAjuste,
    reset: resetModalAjuste,
    setValue: setValueModalAjuste,
    formState: formStateModalAjuste,
  } = useForm({
    resolver: yupResolver(schemaModalAjuste),
    reValidateMode: 'onBlur',
  });
  /* Transporte * */
  const {
    register: registerTransporte,
    handleSubmit: handleSubmitTransporte,
    trigger: triggerTransporte,
    control: controlTransporte,
    reset: resetTransporte,

    setValue: setValueTransporte,
    getValues: getValuesTransporte,
    formState: formStateTransporte,
  } = useForm({
    resolver: yupResolver(schemaTransporte),
    reValidateMode: 'onBlur',
  });
  /* Financeiro * */
  const {
    register: registerFinanceiro,
    handleSubmit: handleItemFinaceiro,
    control: controlFinanceiro,
    reset: resetFinanceiro,
    setValue: setValueFinanceiro,
    formState: formStateFinanceiro,
    getValues: getValueFinanceiro,
    watch: watchFinanceiro,
  } = useForm({
    resolver: yupResolver(schemaFinanceiro),
    reValidateMode: 'onBlur',
  });
  // TESTE
  /* Log * */
  const {
    register: registerLog,
    handleSubmit: handleItemLog,
    control: controlLog,
    reset: resetLog,
    setValue: setValueLog,
    formState: formStateLog,
  } = useForm({
    resolver: yupResolver(schemaLog),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (!update) {
      onClearFinaceiro();
    }
  }, []);

  const OpenModal = OpenModalNovoItem(async () => {
    setValueModal('produto', { value: undefined, label: '' });
    FocusFilter('Buscaproduto');
    if (validationItens()) return;
    resetModalItem();
    setIsOpenModalNovoItem(true);
    setUpdateItemModal(false);
  });

  function validationItens() {
    let result = false;
    if (perfil.tipo_nf === 9) {
      if (loja?.num_cnpj !== fornecedor.num_cpf_cnpj) {
        result = true;
        toast.warning(
          'Notas de perda só podem ser efetuadas para o mesmo grupo de loja.',
        );
      }
    }

    return result;
  }

  const OpenModalAjuste = OpenModalNovoItem(async () => {
    setInit(false);
    setIsOpenModalImportAjuste(true);
    setItensAjuste([]);
  });

  const resetModalItem = () => {
    // resetModal();

    setTimeout(() => {
      setValueModal('produto', {
        label: '',
        value: undefined,
      });
    }, 200);
    setDisable(false);
    clearErrorsModal();
    setUpdateItemModal(false);
    setProduto({} as ProdutosProps);
    setTributacaoNovoItem({ value: undefined, label: '' });
    setValueModal('trib', '');
    setNcmNovoItem({ value: undefined, label: '' });
    setValueModal('ncm', '');
    setCfopNovoItem({ value: undefined, label: '' });
    setValueModal('cfop', '');
    setValueModal('qtd_embalagem', '0');
    setValueModal('qtd_entrada', '0');
    setValueModal('val_unitario', '0');
    setValueModal('val_material', '0');
    setValueModal('val_desconto', '0');
    setValueModal('val_despesa_acessoria', '0');
    setValueModal('val_ipi', '0');
    setValueModal('val_bc_st', '0');
    setValueModal('val_icms_st', '0');
    setValueModal('val_frete', '0');
    setValueModal('pis_cofins', '0');
  };

  function FocusFilter(id: string) {
    const input = document.getElementById(id);
    input?.focus();
  }
  // eslint-disable-next-line no-shadow
  async function buscarProduto(codProduto: number) {
    if (loja !== undefined) {
      const cod_pessoa = getValues('cod_pessoa');
      const { data } = await api.get('nf-outras-operacoes/busca-produto', {
        params: {
          cod_fornecedor: cod_pessoa.value,
          cod_loja: loja.cod_loja,
          cod_produto: codProduto,
        },
      });
      setValueModal('produto', {
        label: data.des_produto,
        value: codProduto,
      });
      console.log({ cod_pessoa, data });
      if (data.cod_ncm !== undefined) {
        const ncm = ncms.find((item) => item.value === data.cod_ncm);
        console.log({ ncm });
        setNcmNovoItem(ncm);
        setValueModal('ncm', ncm);
      }
      setProduto(data);
      // valor
      const val_unitario =
        perfil?.tipo_unidade === 0
          ? formatCurrencyAsText(data?.val_venda, 2, 2)
          : formatCurrencyAsText(data?.val_custo_rep, 2, 2);
      console.log({ val_unitario });
      setValueModal(
        'val_unitario',
        val_unitario === null ? '0' : val_unitario || '0',
      );
      const tributacao = defineTributacaoOutrasOperacoes(
        perfil,
        fornecedor,
        tributacoes,
        data?.cod_tributacao,
        data?.cod_trib_entrada,
        data?.tipo_tributacao,
      );
      console.log({ tributacao });
      setValueModal('trib', tributacao);
      setTributacaoNovoItem(tributacao);

      const cfop = await defineCfopOutrasOperacoes(
        loja,
        fornecedor,
        tributacao?.tipo_tributacao,
        cfops,
      );
      console.log({ cfop });
      setValueModal('cfop', cfop);
      setCfopNovoItem(
        cfop !== undefined
          ? {
              value: cfop.cfop,
              label: `${cfop.cfop} - ${cfop.des_cfop}`,
            }
          : undefined,
      );
      FocusFilter('qtd_embalagem');
    }
  }

  const onSubmitModal = handleSubmitModal(async (data) => {
    // Varificando se desconto é maior que quantida multiplicado pelo valor do produto, se for cancelar operação e focar input de desconto
    if (
      transformAsCurrency(data.val_desconto) >=
      // eslint-disable-next-line prettier/prettier
      transformAsCurrency(data.qtd_entrada) *
        transformAsCurrency(data.val_unitario)
    ) {
      toast.warning(
        'Valor de desconto deve ser menor que o valor total do produto!.',
      );
      setErrorModal('val_desconto', { type: 'focus' }, { shouldFocus: true });
      return;
    }
    // Produto com codigo associado
    if (produto?.cod_associado !== null) {
      await Swal.fire({
        titleText: `Produto Associado! ${produto.cod_associado} Somente é possível dar entrada no Produto-Pai.`,
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'OK',
      });
      return setErrorModal('produto', { type: 'focus' }, { shouldFocus: true });
    }
    // verficando se produto existe
    const produtoIndex = itens.findIndex(
      (prod) => prod.cod_produto === produto.cod_produto,
    );
    if (produtoIndex !== -1 && !updateItemModal) {
      const result = await Swal.fire({
        titleText: 'Item já incluído. \n Deseja incluí-lo novamente?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
      });
      if (!result.isConfirmed) return;
    }
    let ncm: any;
    if (produto?.cod_ncm !== undefined) {
      ncm = await api.get(`/ncm/${data?.ncm?.value}`);
    }

    const val_tabela_liq = calculaValTabelaLiq(
      data.qtd_entrada, // qtd entrada
      data.val_unitario, // valor Unitario
      data.val_despesa_acessoria, // valor val_despesa_acessoria
      data.val_desconto, // valor desconto
      data.val_frete, // valor frete
    );

    const val_ipi_calc = calculaValorIpi(
      val_tabela_liq,
      transformAsCurrency(data.val_ipi),
      0,
    );
    const val_tabela_final = calculaTabFinal(val_tabela_liq, val_ipi_calc);
    const val_reducao_base_calculo = calculaReducao(
      transformAsCurrency(val_tabela_liq),
      transformAsCurrency(data?.trib?.val_reducao),
    );
    const val_bc_icms = [0, 2].includes(data.trib?.tipo_tributacao)
      ? val_reducao_base_calculo
      : 0;

    let val_icms;
    if (data.trib?.tipo_tributacao === 2 || data.trib?.tipo_tributacao === 0) {
      val_icms = calculaIcms(
        val_tabela_liq,
        transformAsCurrency(data.trib?.val_icms),
        transformAsCurrency(data.trib?.val_reducao),
        transformAsCurrency(data.trib?.tipo_tributacao),
        false,
        false,
      );
    } else {
      val_icms = 0;
    }
    let val_outros = 0;
    let val_isento = 0;

    if (![0, 1, 2, 8].includes(data?.trib?.tipo_tributacao)) {
      val_outros =
        transformAsCurrency(val_tabela_liq) - transformAsCurrency(val_bc_icms);
    } else {
      val_isento = TruncaFrac(
        transformAsCurrency(val_tabela_liq) - transformAsCurrency(val_bc_icms),
        3,
      );
    }
    const val_total_nf =
      transformAsCurrency(val_tabela_liq) +
      transformAsCurrency(data.val_ipi) +
      transformAsCurrency(data?.val_icms_st);

    const flg_nao_pis_cofins =
      ncm?.data?.data?.tipos[0]?.flg_nao_pis_cofins || false;

    let val_base_ipi = 0;
    let val_isento_ipi = 0;
    // eslint-disable-next-line prefer-const
    let val_outros_ipi = 0;

    if (transformAsCurrency(data.val_ipi) !== 0.0) {
      val_base_ipi = val_tabela_final - transformAsCurrency(data.val_ipi);
    } else {
      val_isento_ipi = val_tabela_final;
    }

    const pisCofins = procedureCalculaPISCofins(
      flg_nao_pis_cofins,
      produto?.flg_nao_pis_cofins,
      fornecedor?.flg_nao_transf_pis_cofins,
      parametroLoja?.loja?.flg_st_bc_pis_cof,
      parametroLoja?.loja?.flg_ipi_bc_pis_cof,
      produto?.tipo_nao_pis_cofins,
      val_total_nf,
      // eslint-disable-next-line prettier/prettier
      transformAsCurrency(data?.val_icms_st) +
        transformAsCurrency(data?.val_bc_st),
      transformAsCurrency(data?.val_ipi),
      0,
      0,
    );

    const series = getValues('num_serie');
    const item = {
      uuid: nanoid(),
      num_item:
        updateItemModal && numItem !== undefined
          ? numItem
          : Number(itens[itens.length - 1]?.num_item || 0) + 1,
      cod_produto: produto.cod_produto,
      cod_seq_ajuste_estoque: undefined,
      des_produto: produto.des_produto,
      cod_gtin: produto.cod_gtin_principal,
      cfop: data.cfop.cfop,
      cod_sit_tributaria: [7, 9].includes(perfil.tipo_nf)
        ? '090'
        : data?.trib.cod_situacao_tributaria,
      cod_id_ctb: data.cfop.cod_id_ctb,
      cod_ncm: produto?.cod_ncm,
      num_ncm: ncm?.data?.data?.num_ncm,
      cod_tributacao: data?.trib.cod_tributacao,
      tipo_tributacao: data?.trib?.tipo_tributacao,
      per_aliq_icms: [7, 9].includes(perfil.tipo_nf) ? 0 : val_icms,
      per_red_bc_icms: data.trib?.val_reducao,
      qtd_embalagem: transformAsCurrency(data.qtd_embalagem),
      qtd_entrada: transformAsCurrency(data.qtd_entrada),
      qtd_total:
        transformAsCurrency(data.qtd_embalagem) *
        transformAsCurrency(data.qtd_entrada),
      val_unitario: transformAsCurrency(data.val_unitario),
      val_total:
        transformAsCurrency(data.val_unitario) *
        transformAsCurrency(data?.qtd_entrada),
      val_desconto: transformAsCurrency(data.val_desconto),
      val_despesa_acessoria: transformAsCurrency(data.val_despesa_acessoria),
      val_tabela_liq: parseFloat(val_tabela_liq.toFixed(3)),
      val_tabela_final: parseFloat(val_tabela_final.toFixed(3)),
      val_bc_icms,
      val_icms,
      val_outros,
      val_isento,
      val_bc_st: transformAsCurrency(data?.val_bc_st),
      val_icms_st: transformAsCurrency(data?.val_icms_st),
      flg_nao_pis_cofins: produto?.flg_nao_pis_cofins,
      tipo_nao_pis_cofins: produto?.tipo_nao_pis_cofins,
      per_icms: transformAsCurrency(data?.trib?.val_icms),
      val_reducao: transformAsCurrency(data?.trib?.val_reducao),
      val_frete: transformAsCurrency(data?.val_frete),
      val_ipi: transformAsCurrency(data.val_ipi),
      val_base_ipi,
      val_isento_ipi,
      val_outros_ipi,
      isDisabled: false,
      isDisabledNF: false,
      val_inputs: {
        produto,
        tributacao: data?.trib,
        cfop: data?.cfop,
        ncm: ncm?.data?.data,
        qtd_embalagem: transformAsCurrency(data.qtd_embalagem),
        qtd_entrada: transformAsCurrency(data.qtd_entrada),
        val_unitario: transformAsCurrency(data.val_unitario),
        val_material: transformAsCurrency(data.val_material),
        val_desconto: transformAsCurrency(data.val_desconto),
        val_despesa_acessoria: transformAsCurrency(data.val_despesa_acessoria),
        val_ipi: transformAsCurrency(data.val_ipi),
        val_bc_st: transformAsCurrency(data.val_bc_st),
        val_icms_st: transformAsCurrency(data.val_icms_st),
        val_frete: transformAsCurrency(data.val_frete),
        pis_cofins: transformAsCurrency(data.pis_cofins),
        bonificado: data.bonificado,
      },
      ...pisCofins,
    };

    if (updateItemModal) {
      const removidoItem = itens.filter((i: any) => i.num_item !== numItem);
      const itemComAjustes = procedureValidationItem(
        item,
        flg_nao_pis_cofins,
        series,
      );
      setItens([...removidoItem, itemComAjustes]);
      toast.success('Produto foi alterado com sucesso');
    } else {
      const itemComAjustes = procedureValidationItem(
        item,
        flg_nao_pis_cofins,
        series,
      );
      toast.success('Produto foi Incluso ao lançamento');

      setItens([...itens, itemComAjustes]);
    }
    resetModalItem();
    if (!updateItemModal) {
      FocusFilter('Buscaproduto');
    }
  });

  const getProdutos = buscarProdutoAjuste(async (data) => {
    try {
      const cod_pessoa = getValues('cod_pessoa');
      const cod_loja = getValues('cod_loja');
      const importAjuste = [];
      const result = await api.get('nf-outras-operacoes/importar-ajustes', {
        params: {
          cod_loja,
          cod_fornecedor: cod_pessoa.value,
          cod_ajuste: data.tipo_ajuste.value,
          dta_ini_ajuste: data.dta_ini_ajuste,
          dta_fim_ajuste: data.dta_fim_ajuste,
        },
      });
      itensAjustesRelacionados;
      let count = 0;
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of result.data.data) {
        const ajusteUtilizado = itensAjustesRelacionados.some(
          (i) => i.cod_seq_ajuste_estoque === item.cod_seq_ajuste_estoque,
        );

        if (!ajusteUtilizado) {
          count += 1;
          importAjuste.push({
            ...item,
            id: count,
            marca: false,
            loja: cod_loja,
            cod_ajuste: item.cod_seq_ajuste_estoque,
            qtd_ajuste: Math.abs(item.qtd_ajuste),
            desc_ajuste: data.tipo_ajuste.label,
            dta_ajuste: item.dta_ajuste,
            cod_produto: item.cod_produto,
            des_produto: item.des_produto,
          });
        }
      }

      setItensAjuste(importAjuste);
      setInit(true);
    } catch (error) {
      toast.error(`${error}`);
    }
  });
  function checkAllItens(marcarTodos: boolean) {
    const itensajuste = itensAjuste;
    for (let i = 0; i < itensajuste.length; i++) {
      itensajuste[i].marca = marcarTodos;
    }
    setItensAjuste(itensajuste);
  }

  function checkItem(id: number, marca: boolean) {
    const itensajuste = itensAjuste;
    const index = itensajuste.findIndex((i) => i.id === id);

    if (index !== -1) {
      itensajuste[index].marca = marca;
    }

    setItensAjuste([...itensajuste]);
  }

  async function handleSubmitModalAjuste() {
    setLoadingAjuste(true);
    if (loja) {
      let allItens = itens;
      const allAjuste = itensAjustesRelacionados;
      const selectedItensAjuste = itensAjuste.filter(
        (item) => item.marca === true,
      );
      if (selectedItensAjuste.length === 0) {
        setLoadingAjuste(false);
        return toast.warning('Ajuste(s) deve(m) ser selecionado(s)');
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const itemAjuste of selectedItensAjuste) {
        const ajusteUtilizado = allAjuste.some(
          (i) => i.cod_seq_ajuste_estoque === itemAjuste.cod_ajuste,
        );
        if (!ajusteUtilizado) {
          const indexItem = allItens.findIndex(
            (i: ItensProps) =>
              i.cod_produto === itemAjuste.cod_produto &&
              Math.abs(i.val_unitario) ===
                (Math.abs(itemAjuste.val_custo_rep) === 0
                  ? 0
                  : Math.abs(itemAjuste.val_custo_rep) /
                    Math.abs(itemAjuste.qtd_ajuste)),
          );
          let ncm: any;
          if (produto?.cod_ncm !== undefined) {
            ncm = await api.get(`/ncm/${produto?.cod_ncm}`);
          }
          // Selecionando Tributacao
          const tributacao = defineTributacaoOutrasOperacoes(
            perfil,
            fornecedor,
            tributacoes,
            itemAjuste.cod_tributacao,
            itemAjuste.cod_trib_entrada,
            itemAjuste.tipo_tributacao,
          );
          const cfop = defineCfopOutrasOperacoes(
            loja,
            fornecedor,
            tributacao?.tipo_tributacao,
            cfops,
          );
          // eslint-disable-next-line prettier/prettier
          let qtd_embalagem =
            itemAjuste?.qtd_embalagem_venda <= 0
              ? 1
              : itemAjuste?.qtd_embalagem_venda;
          let qtd_entrada = Math.abs(itemAjuste.qtd_ajuste);
          // eslint-disable-next-line prettier/prettier
          let val_unitario =
            Math.abs(itemAjuste.val_custo_rep) === 0
              ? 0
              : Math.abs(itemAjuste.val_custo_rep) /
                Math.abs(itemAjuste.qtd_ajuste);

          if (indexItem !== -1) {
            qtd_embalagem =
              allItens[indexItem].qtd_embalagem +
              (itemAjuste?.qtd_embalagem_venda <= 0
                ? 1
                : itemAjuste?.qtd_embalagem_venda);
            qtd_entrada =
              allItens[indexItem].qtd_entrada + Math.abs(itemAjuste.qtd_ajuste);
            val_unitario =
              Math.abs(itemAjuste.val_custo_rep) === 0
                ? 0
                : Math.abs(itemAjuste.val_custo_rep) /
                  Math.abs(itemAjuste.qtd_ajuste);
          }
          const val_tabela_liq = calculaValTabelaLiq(
            qtd_entrada,
            val_unitario,
            0,
            0,
            0,
          );
          const val_tabela_final = calculaTabFinal(val_tabela_liq, 0);

          const val_reducao_base_calculo = calculaReducao(
            transformAsCurrency(val_tabela_liq),
            transformAsCurrency(tributacao?.val_reducao),
          );

          const val_bc_icms = [0, 2].includes(tributacao?.tipo_tributacao)
            ? val_reducao_base_calculo
            : 0;

          let val_icms = 0;
          if (
            tributacao?.tipo_tributacao === 2 ||
            tributacao?.tipo_tributacao === 0
          ) {
            val_icms = calculaIcms(
              val_tabela_liq,
              transformAsCurrency(tributacao?.val_icms),
              transformAsCurrency(tributacao?.val_reducao),
              tributacao?.tipo_tributacao,
              false,
              false,
            );
          }
          let val_outros = 0;
          let val_isento = 0;
          if (![0, 1, 2, 8].includes(tributacao?.tipo_tributacao)) {
            val_outros =
              transformAsCurrency(val_tabela_liq) -
              transformAsCurrency(val_bc_icms);
          } else {
            val_isento = TruncaFrac(
              transformAsCurrency(val_tabela_liq) -
                transformAsCurrency(val_bc_icms),
              3,
            );
          }
          const val_total_nf =
            transformAsCurrency(val_tabela_liq) +
            transformAsCurrency(0) +
            transformAsCurrency(val_icms);

          const flg_nao_pis_cofins =
            ncm?.data?.data?.tipos[0]?.flg_nao_pis_cofins || false;

          const val_base_ipi = 0;
          const val_isento_ipi = val_tabela_final;
          const val_outros_ipi = 0;

          const pisCofins = procedureCalculaPISCofins(
            flg_nao_pis_cofins,
            itemAjuste?.flg_nao_pis_cofins,
            fornecedor?.flg_nao_transf_pis_cofins,
            parametroLoja?.loja?.flg_st_bc_pis_cof,
            parametroLoja?.loja?.flg_ipi_bc_pis_cof,
            itemAjuste?.tipo_nao_pis_cofins,
            val_total_nf,
            // eslint-disable-next-line prettier/prettier
            transformAsCurrency(val_icms) + transformAsCurrency(val_bc_icms),
            0,
            0,
            0,
          );

          const series = getValues('num_serie');
          const item = {
            uuid: nanoid(),
            num_item: Number(allItens[allItens.length - 1]?.num_item || 0) + 1,
            cod_produto: itemAjuste?.cod_produto,
            des_produto: itemAjuste?.des_produto,
            cod_gtin: itemAjuste?.cod_gtin_principal,
            cfop: cfop?.cfop,
            cod_sit_tributaria: [7, 9].includes(perfil.tipo_nf)
              ? '090'
              : tributacao.cod_situacao_tributaria,
            cod_id_ctb: itemAjuste?.cod_id_ctb,
            cod_ncm: itemAjuste?.cod_ncm,
            num_ncm: itemAjuste?.num_ncm,
            cod_tributacao: tributacao?.cod_tributacao,
            tipo_tributacao: tributacao?.tipo_tributacao,
            per_aliq_icms: [7, 9].includes(perfil.tipo_nf) ? 0 : val_icms,
            per_red_bc_icms: tributacao.val_reducao,
            qtd_embalagem,
            qtd_entrada,
            qtd_total: qtd_entrada * qtd_embalagem,
            val_unitario,
            val_total: qtd_entrada * val_unitario,
            val_desconto: 0,
            val_despesa_acessoria: 0,
            val_tabela_liq: parseFloat(val_tabela_liq.toFixed(3)),
            val_tabela_final,
            val_bc_icms,
            val_icms,
            val_outros,
            val_isento,
            val_bc_st: 0,
            val_icms_st: 0,
            val_ipi: 0,
            flg_nao_pis_cofins: itemAjuste?.flg_nao_pis_cofins,
            tipo_nao_pis_cofins: itemAjuste?.tipo_nao_pis_cofins,
            per_icms: tributacao?.val_icms,
            val_reducao: tributacao?.val_reducao,
            val_frete: 0,
            isDisabledEdit: true,
            isDisabled: false,
            isDisabledNF: false,
            val_base_ipi,
            val_isento_ipi,
            val_outros_ipi,
            val_inputs: {
              produto: itemAjuste,
              tributacao,
              cfop,
              ncm: ncm?.data?.data,
              qtd_embalagem,
              qtd_entrada,
              val_unitario,
              val_material: 0,
              val_desconto: 0,
              val_despesa_acessoria: 0,
              val_ipi: 0,
              val_bc_st: val_bc_icms,
              val_icms_st: val_icms,
              val_frete: 0,
              pis_cofins: 0,
              bonificado: false,
            },
            ...pisCofins,
          };

          const itemComAjustes = procedureValidationItem(
            item,
            flg_nao_pis_cofins,
            series,
          );
          if (indexItem !== -1) {
            itemComAjustes.uuid = allItens[indexItem].uuid;
            allItens[indexItem] = itemComAjustes;
            allAjuste.push({
              uuid: itemComAjustes.uuid,
              cod_seq_ajuste_estoque: itemAjuste.cod_ajuste,
            });
          } else {
            allItens.push(itemComAjustes);
            allAjuste.push({
              uuid: itemComAjustes.uuid,
              cod_seq_ajuste_estoque: itemAjuste.cod_ajuste,
            });
          }
        }
      }
      // ordenando
      allItens = allItens.map((item, index: number) => {
        item.num_item = index + 1;
        return item;
      });
      setItens([...allItens]);
      setItensAjustesRelacionados([...allAjuste]);
    }

    setTimeout(() => {
      setLoadingAjuste(false);
      setIsOpenModalImportAjuste(false);
      setItensAjuste([]);
    }, 2000);
  }

  function updateItem(item: any, num_item: number) {
    // resetModal();

    setProduto(item.produto);

    setTributacaoNovoItem(item.tributacao);
    setNcmNovoItem({
      value: item?.cod_ncm,
      label: `${item.ncm?.num_ncm} -  ${item?.ncm?.des_ncm} - CEST ${item.ncm?.cod_cest}`,
    });

    setCfopNovoItem(item.cfop);
    setCfopNovoItem(
      item.cfop !== undefined
        ? {
            value: item.cfop.cfop,
            label: `${item.cfop.cfop} - ${item.cfop.des_cfop}`,
          }
        : undefined,
    );
    setValueModal('qtd_embalagem', formatCurrencyAsText(item.qtd_embalagem));
    setValueModal('qtd_entrada', formatCurrencyAsText(item.qtd_entrada));
    setValueModal('val_unitario', formatCurrencyAsText(item.val_unitario));
    setValueModal('val_material', formatCurrencyAsText(item.val_material));
    setValueModal('val_desconto', formatCurrencyAsText(item.val_desconto));
    setValueModal(
      'val_despesa_acessoria',
      formatCurrencyAsText(item.val_despesa_acessoria),
    );
    setValueModal('val_ipi', formatCurrencyAsText(item.val_ipi));
    setValueModal('val_bc_st', formatCurrencyAsText(item.val_bc_st));
    setValueModal('val_icms_st', formatCurrencyAsText(item.val_icms_st));
    setValueModal('val_frete', formatCurrencyAsText(item.val_frete));
    setValueModal('pis_cofins', formatCurrencyAsText(item.pis_cofins));

    setTimeout(() => {
      setValueModal('ncm', {
        value: item.ncm?.cod_ncm,
        label: `${item.ncm?.num_ncm} -  ${item.ncm?.des_ncm} - CEST ${item.ncm?.cod_cest}`,
      });
      setValueModal('cfop', item.cfop);
      setValueModal('trib', item.tributacao);
      setValueModal('produto', {
        label: item.produto.des_produto,
        value: item.produto.cod_produto,
      });
    }, 200);
    setNumItem(num_item);
    setUpdateItemModal(true);
    setIsOpenModalNovoItem(true);
  }
  function ordernacaoItem(_itens: ItensProps[]): ItensProps[] {
    const itensOrdenados = _itens.map((item, index) => {
      return {
        ...item,
        num_item: index + 1,
      };
    });
    return itensOrdenados;
  }

  function deleteItem(Item: any) {
    Swal.fire({
      title: ``,
      text: 'Deseja realmente excluir esse item?',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const removidoItemAjuste = itensAjustesRelacionados.filter(
          (item: any) => item.uuid !== Item.uuid,
        );
        const removidoItem = itens.filter(
          (item: any) => item.num_item !== Item.num_item,
        );
        const itensOrdenados = ordernacaoItem(removidoItem);
        setItens(itensOrdenados);
        setItensAjustesRelacionados(removidoItemAjuste);
      }
    });
  }

  // Aba Financeiro
  function onClearFinaceiro() {
    // resetFinanceiro();
    setValueFinanceiro('dta_vencimento', '');
    setValueFinanceiro('num_condicao', 30);
    setValueFinanceiro('condicao', {
      cod_condicao: 2,
      des_condicao: 'DIAS DA DATA',
      des_definicao: 'DD',
      label: 'DD - DIAS DA DATA',
      value: 2,
    });
    setValueFinanceiro('cod_finalizadora', '');
    setValueFinanceiro('val_financeiro', '');
    setUpdateFinaceiro(false);
  }

  const addItemFinaceiro = handleItemFinaceiro(async (data) => {
    const {
      num_condicao,
      cod_finalizadora,
      condicao,
      dta_vencimento,
      val_financeiro,
    } = data;

    const itemFinaceiro = {
      num_registro:
        updateFinaceiro && numItemFinaceiro !== undefined
          ? numItemFinaceiro
          : Number(
              itensFinaceiro[itensFinaceiro.length - 1]?.num_registro || 0,
            ) + 1,
      num_condicao,
      des_definicao: condicao.label,
      cod_condicao: condicao.value,
      cod_finalizadora: cod_finalizadora.value,
      des_finalizadora: cod_finalizadora.label,
      cod_cc: 0,
      cod_categoria: 0,
      dta_vencimento,
      val_parcela: transformAsCurrency(val_financeiro),
      flg_quitado: false,
      isDisabled: false,
    };
    if (updateFinaceiro) {
      const removidoItem = itensFinaceiro.filter(
        (i: ItensFinaceiroProps) => i.num_registro !== numItemFinaceiro,
      );
      setItensFinaceiro([...removidoItem, itemFinaceiro]);
    } else {
      setItensFinaceiro([...itensFinaceiro, itemFinaceiro]);
    }
    onClearFinaceiro();
  });

  function validarValorSerie(num_serie: string | undefined) {
    return num_serie !== undefined && num_serie !== '';
  }

  async function validation() {
    const num_nf = getValues('num_nf');
    const cod_perfil = getValues('cod_perfil');
    const num_especie = getValues('num_especie');
    const num_serie = getValues('num_serie');

    if (loja?.cod_loja === undefined) {
      toast.warning('Loja deve ser selecionada');
      setCurrentTab('nfe');
      return false;
    }

    if (fornecedor?.value === undefined) {
      toast.warning('Fornecedor deve ser selecionado');
      setCurrentTab('nfe');
      return false;
    }

    const { data } = await api.get(
      `/nf-outras-operacoes/validacao-endereco/${fornecedor.cod_pessoa}`,
    );
    if (!data.success) {
      toast.warning(data.message);
      setCurrentTab('nfe');
      return false;
    }
    if (cod_perfil === undefined || cod_perfil === '') {
      toast.warning('Perfil deve ser selecionado');
      setCurrentTab('nfe');
      return false;
    }

    if (cod_perfil?.tipo_operacao === 0 && cod_perfil.tipo_nf === 7) {
      toast.warning(
        'Perfil informado está cadastrado como Operação de "Entrada" e Tipo NF "Uso e Consumo Próprio" esse Tipo NF só pode ser utilizado para operação de "Saída"',
      );
      setCurrentTab('nfe');
      return false;
    }
    const valorSerie =
      cod_perfil?.tipo_emissao === 0 ? num_serie : num_serie.value;
    if (!validarValorSerie(valorSerie)) {
      toast.warning('Série deve ser informada.');
      setCurrentTab('nfe');
      return false;
    }
    if (num_especie.value === undefined || num_especie.value === '') {
      toast.warning('Espécie deve ser informada.');
      setCurrentTab('nfe');
      return false;
    }
    if (perfil.tipo_emissao !== 1 && num_nf === '') {
      toast.warning('Número da NF deve ser informada');
      setCurrentTab('nfe');
      return false;
    }
    if (dtaEmissao === '') {
      toast.warning('Data de Emissão deve ser informada.');
      setCurrentTab('nfe');
      return false;
    }

    if (dtaEntrada === '') {
      toast.warning('Data de Saída deve ser informada');
      setCurrentTab('nfe');
      return false;
    }

    if (
      cod_perfil.tipo_emissao === 0 &&
      new Date(dtaEntrada) < new Date(dtaEmissao)
    ) {
      toast.warning(
        'Data de Emissão não deve ser maior que Data de Entrada/Saída.',
      );
      setCurrentTab('nfe');
      return false;
    }

    if (
      cod_perfil.tipo_emissao === 1 &&
      isBefore(startOfDay(parseISO(dtaEntrada)), startOfDay(new Date()))
    ) {
      toast.warning(
        'Data de Entrada/Saída deve ser maior ou igual a data atual.',
      );
      setCurrentTab('nfe');
      return false;
    }

    if (cod_perfil.tipo_nf === 7 || cod_perfil.tipo_nf === 9) {
      if (
        num_especie.des_especie === 'NFCEE' ||
        num_especie.des_especie === 'NFCFA' ||
        num_especie.des_especie === 'NFCFG'
      ) {
        toast.warning(
          'Espécies NFCEE, NFCFA e NFCFG não permitidas para notas de Perda/Uso e Consumo.',
        );
        return false;
      }
    }

    if (
      (cod_perfil.tipo_emissao === 0 && num_especie.des_especie === 'NFE') ||
      num_especie.des_especie === 'NF3E'
    ) {
      toast.warning(
        'Para NF de entrada de espécie NFE ou NF3E o número da Chave de Acesso deve ser informado.',
      );
      return false;
    }
    if (itens.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado(s)');
      return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const item of itens) {
      if (item.cfop === undefined) {
        toast.warning(`CFOP do item ${item.num_item} deve ser informada.`);
        return false;
      }
      if (item.qtd_embalagem < 1) {
        toast.warning(
          `Quantidade de embalagem do item ${item.num_item} deve ser maior que 0.`,
        );
        return false;
      }
      if (item.qtd_entrada < 1) {
        toast.warning(
          `Quantidade do item ${item.num_item} deve ser maior que 0.`,
        );
        return false;
      }
      if (item.val_unitario <= 0) {
        toast.warning(`Valor do item ${item.num_item} deve ser maior que 0.`);
        return false;
      }
    }
    return true;
  }
  function updateItemFinaceiro(item: ItensFinaceiroProps, num_item: number) {
    setUpdateFinaceiro(true);
    setNumItemFinaceiro(num_item);
    setValueFinanceiro('dta_vencimento', item.dta_vencimento);
    setValueFinanceiro('num_condicao', item.num_condicao);
    setValueFinanceiro('condicao', {
      value: item.cod_condicao,
      label: item.des_definicao,
    });
    setValueFinanceiro('cod_finalizadora', {
      value: item.cod_finalizadora,
      label: item.des_finalizadora,
    });
    setValueFinanceiro(
      'val_financeiro',
      formatCurrencyAsText(item.val_parcela),
    );
  }

  function deleteItemFinaceiro(id: number) {
    Swal.fire({
      title: ``,
      text: 'Deseja realmente remover essa parcela?',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const removidoItemFinaceiro = itensFinaceiro.filter(
          (item) => item.num_registro !== id,
        );
        setItensFinaceiro(removidoItemFinaceiro);
      }
    });
  }

  function validationFinanceiro() {
    if (fornecedor?.value === undefined) {
      toast.warning('Fornecedor deve ser selecionado');
      return false;
    }
    if (dtaEmissao === '') {
      toast.warning('Data de Emissão deve ser informada.');
      return false;
    }

    if (dtaEntrada === '') {
      toast.warning('Data de Saída deve ser informada');
      return false;
    }
    if (itens.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado');
      return false;
    }
    return true;
  }
  async function processConditions() {
    const dta_emissao = getValues('dta_emissao');
    const dta_entrada = getValues('dta_entrada');
    const res = await getCondicaoPessoaFornecedor(
      fornecedor.value,
      perfil.cod_cc,
      perfil.cod_categoria,
      perfil.cod_perfil,
      perfil.tipo_operacao,
      total.Total_Tab,
      dta_emissao,
      dta_entrada,
    );
    if (res.length > 0) {
      setItensFinaceiro(res);
    } else {
      Swal.fire({
        text: `Nenhuma condição de Pagamento localizada para esse parceiro/perfil`,
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  async function financeiroCalcular() {
    if (!validationFinanceiro()) return;
    let realizaBuscaParcelas = true;
    if (itensFinaceiro.length > 0) {
      await Swal.fire({
        text: `Deseja pesquisar novamente as condições de pagamento deste parceiro/perfil?
        As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SIM',
        cancelButtonText: 'NÃO',
      }).then(async (result) => {
        if (result.isConfirmed) realizaBuscaParcelas = true;
        else realizaBuscaParcelas = false;
      });
    }
    if (realizaBuscaParcelas) await processConditions();
  }

  async function parcelaFinanceira(cod_seq_nf: number) {
    const result = await getParcelasFinanceiro(cod_seq_nf);
    setItensFinaceiro(result);
  }

  async function pushItens(cod_seq_nf: number) {
    const result = await getItens(cod_seq_nf, tributacoes, cfops, ncms);
    setItens(result);
  }
  // Enviando para Backend
  const onSubmit = async () => {
    try {
      clearErrors();
      if (!(await validation())) return;
      if (perfil.flg_gera_financeiro && itensFinaceiro.length === 0) {
        toast.warning('Parcela(s) do financeiro deve(m) ser informada(s).');
        return;
      }
      const totalFinanceiro = itensFinaceiro.reduce(
        (sum, { val_parcela }) => sum + val_parcela,
        0,
      );
      if (
        perfil.flg_gera_financeiro &&
        total.Total_Nota_Fiscal.toFixed(2) !== totalFinanceiro.toFixed(2)
      ) {
        toast.warning('Total Parcelas difere Total NF');
        return;
      }
      const serie = getValues('num_serie');
      const especie = getValues('num_especie');
      const num_nf_local = getValues('num_nf');
      const serieFormatada = perfil.tipo_emissao === 0 ? serie : serie.value;
      const tipo_nf = [10, 14].includes(perfil.tipo_nf) ? perfil.tipo_nf : 6;

      const nfData = {
        loja,
        perfil,
        tipo_nf,
        fornecedor,
        serie: serieFormatada,
        especie,
        num_nf: perfil.tipo_operacao === 1 ? serieFormatada : num_nf_local,
        cod_xml,
        numChaveAcesso,
        dtaEmissao,
        dtaEntrada,
        itens,
        total,
        itensAjustesRelacionados,
        transporte: {
          val_seguro: transformAsCurrency(getValuesTransporte('val_seguro')),
          qtd_embalagem: transformAsCurrency(
            getValuesTransporte('qtd_embalagem'),
          ),
          qtd_peso_liq: transformAsCurrency(
            getValuesTransporte('qtd_peso_liq'),
          ),
          qtd_peso_bruto: transformAsCurrency(
            getValuesTransporte('qtd_peso_bruto'),
          ),
          des_marca_veiculo: getValuesTransporte('des_marca_veiculo'),
          des_placa_veiculo: getValuesTransporte('des_placa_veiculo'),
          des_obs_fiscal: getValuesTransporte('des_obs_fiscal'),
        },
        financeiro: itensFinaceiro,
      };
      const { data } = await api.post('nf-outras-operacoes', nfData);

      if (data.success) {
        const { cod_seq_nf, num_nf } = data.data;

        if (perfil.tipo_emissao === 1) {
          await transmitirNFe({
            cod_loja: Number(loja!.cod_loja),
            cod_pessoa: fornecedor.cod_pessoa,
            num_nf,
            tipo_nf,
            cod_seq_nf,
          });
        }

        onClearForm();
        toast.success(data.message);
      } else {
        toast.warning(data.message);
      }
    } catch (error: any) {
      if (error?.message) console.error(String(error?.message));
      if (error?.data?.message) console.error(String(error?.data?.message));
    }
  };

  const onClearForm = async () => {
    setUpdate(false);
    setTransmissao(false);
    setDisable(false);
    setCurrentTab('nfe');
    setCodSeqNf(undefined);
    setLoading(false);
    // reset nao controlados
    setSerieSelect('');
    reset();
    setValue('cod_pessoa', '');
    setValue('cod_perfil', '');
    setValue('num_serie', '');
    setValue('num_especie', '');
    setValue('num_nf', '');
    setNumNF('');
    setValue('dta_emissao', '');
    setDtaEmissao('');
    setValue('dta_entrada', '');
    setDtaEntrada('');
    resetModal();
    resetModalAjuste();
    resetTransporte();
    setValueTransporte('val_seguro', '');
    setValueTransporte('qtd_embalagem', '');
    setValueTransporte('qtd_peso_liq', '');
    setValueTransporte('qtd_peso_bruto', '');
    setValueTransporte('des_marca_veiculo', '');
    setValueTransporte('des_placa_veiculo', '');
    setValueTransporte('des_obs_fiscal', '');
    onClearFinaceiro();
    // reset Controlados
    setFornecedor(undefined);
    setPerfil({} as PerfilProps);
    setIsOpenModalNovoItem(false);
    setIsOpenModalImportAjuste(false);
    setTributacaoNovoItem(undefined);
    setNcmNovoItem(undefined);
    setCfopNovoItem(undefined);
    setCondicoes([]);
    // itens
    setItens([]);
    setItensAjuste([]);
    setItensFinaceiro([]);
    setIsBlockNf(false);
    setNumChaveAcesso('');
    setItensAjustesRelacionados([]);
    const { data } = await api.get(`/lojas/${user.loja}`);
    if (data.success) {
      setValue('cod_loja', user.loja);
      setLoja({
        cod_loja: data.data.cod_loja,
        des_loja: data.data.des_loja,
        label:
          data.data.cod_loja < 10
            ? `0${data.data.cod_loja} - ${data.data.des_loja} (${data.data.des_cidade})`
            : `${data.data.cod_loja} - ${data.data.des_loja} (${data.data.des_cidade})`,
        selected: true,
        uf: data.data.des_uf,
        value: data.data.cod_loja,
        num_cnpj: data.data.num_cnpj,
      });
    }
  };
  const onCancel = () => {
    onClearForm();
  };

  function formatPerfil(Perfil: any) {
    return {
      value: Perfil.cod_perfil,
      label: Perfil.descricao,
      cod_categoria: Perfil.cod_categoria,
      cod_cc: Perfil.cod_cc,
      cod_condicao: Perfil.cod_condicao,
      cod_finalizadora: Perfil.cod_finalizadora,
      cod_perfil: Perfil.cod_perfil,
      des_perfil: Perfil.des_perfil,
      descricao: Perfil.descricao,
      num_condicao: Perfil.num_condicao,
      per_desconto: Perfil.per_desconto,
      tipo_emissao: Perfil.tipo_emissao,
      tipo_nf: Perfil.tipo_nf,
      tipo_operacao: Perfil.tipo_operacao,
      tipo_unidade: Perfil.tipo_unidade,
      flg_gera_financeiro: Perfil.flg_gera_financeiro,
    };
  }

  async function importXML(item: NfProps) {
    setLoading(true);
    setIsBlockNf(true);
    function formatFornecedor(fornecedorI: any) {
      return {
        label: `${fornecedorI.cod_pessoa} - ${fornecedorI.des_fornecedor} (${fornecedorI.num_cpf_cnpj})`,
        value: fornecedorI.cod_pessoa,
        cod_trib_simples: fornecedorI.cod_trib_simples,
        flg_optante_simples: fornecedorI.flg_optante_simples,
        des_uf: fornecedorI.des_uf,
        flg_fornecedor: fornecedorI.flg_fornecedor,
        cod_pessoa: fornecedorI.cod_pessoa,
        num_rg_ie: fornecedorI.num_rg_ie || 'ISENTO',
        flg_nao_transf_pis_cofins: fornecedorI.flg_nao_transf_pis_cofins,
      };
    }

    // function formatCFOP(cfop: CFOPSProps) {
    //   return {
    //     ...cfop,
    //     cfop: Number(cfop.cfop),
    //     value: cfop.cfop,
    //     label: `${cfop.cfop} - ${cfop.des_cfop}`,
    //   };
    // }
    // loja
    const { data } = await api.get(`/lojas/${item.nf.cod_loja}`);
    setValue('cod_loja', data.data.cod_loja);
    setLoja(data.data);
    // Fornecedor
    const parceiro = await api.get(`/fornecedor/${item.nf.cod_pessoa}`);
    const resultFornecedor: any = formatFornecedor(parceiro.data.data);

    setValue('cod_pessoa', resultFornecedor);
    setFornecedor(resultFornecedor);
    // Perfil
    const perfilDocumento = await api.get(`/perfil-nf-outras-operacoes/`, {
      params: {
        cod_loja: item.nf.cod_loja,
        cod_perfil: item.nf.cod_perfil,
      },
    });
    const perfilFinal = formatPerfil(perfilDocumento.data.data);
    setValue('cod_perfil', perfilFinal);
    setPerfil(perfilFinal);
    // num nf
    setNumNF(item.nf.num_nf);
    setValue('num_nf', item.nf.num_nf);
    // serie
    if (perfilFinal && Number(perfilFinal.tipo_emissao) === 1) {
      setValue('num_serie', {
        value: Number(item.nf.num_serie_nf),
        label: item.nf.num_serie_nf,
      });
    } else {
      setValue('num_serie', item.nf.num_serie_nf);
    }
    setSerieSelect(item.nf.num_serie_nf);
    // especie
    const especie = especieList.find((i: any) => i.des_especie === 'NFE');
    setValue('num_especie', { value: especie?.value, label: 'NFE' });
    // data emissão
    const dateEmissao = moment(new Date(item.nf.dta_emissao)).format(
      'YYYY-MM-DD',
    );
    setDtaEmissao(dateEmissao);
    setValue('dta_emissao', dateEmissao);

    setDtaEntrada(update ? dateEmissao : '');
    setValue('dta_entrada', update ? dateEmissao : '');
    // chave
    setNumChaveAcesso(item.nf.num_chave_acesso);

    // itens
    const ItensNF: any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const nfItem of item.itens) {
      const {
        cod_tributacao,
        tipo_tributacao,
        per_icms,
        val_reducao_base_calculo,
        cod_sit_tributacao,
        tributacao,
      } = await procedureCalculaItensXml({
        fornecedor: resultFornecedor,
        nf_xml_item: nfItem,
      });
      const prod = await api.get('nf-outras-operacoes/busca-produto', {
        params: {
          cod_fornecedor: item.nf.cod_pessoa,
          cod_loja: item.nf.cod_loja,
          cod_produto: nfItem.cod_produto,
          cfop: nfItem.cfop,
        },
      });
      const cfopFinal = cfops.find((ItemCfop: CFOPSProps) => {
        return Number(ItemCfop.cfop) === Number(nfItem.cfop);
      });
      const ncm = await api.get(`/ncm/${prod.data.cod_ncm}`);
      const qtd_embalagem =
        transformAsCurrency(nfItem.qtd_embalagem) <= 0
          ? 1
          : transformAsCurrency(nfItem.qtd_embalagem);
      const qtd_entrada =
        transformAsCurrency(nfItem.qtd_entrada) <= 0
          ? 1
          : transformAsCurrency(nfItem.qtd_entrada);

      const val_tabela_liq = calculaValTabelaLiq(
        qtd_entrada,
        nfItem.val_unitario,
        nfItem.val_despesa_acessoria,
        nfItem.val_desconto,
        nfItem.val_frete,
      );

      const val_ipi_calc = calculaValorIpi(
        val_tabela_liq,
        transformAsCurrency(nfItem.val_ipi),
        0,
      );
      const val_tabela_final = calculaTabFinal(val_tabela_liq, val_ipi_calc);

      const val_bc_icms = [0, 2].includes(tipo_tributacao)
        ? val_reducao_base_calculo
        : 0;

      let val_icms;
      if ([0, 2].includes(tipo_tributacao)) {
        val_icms = calculaIcms(
          val_tabela_liq,
          transformAsCurrency(nfItem?.per_aliq_icms_xml),
          transformAsCurrency(nfItem?.val_reducao_ent),
          transformAsCurrency(tipo_tributacao),
          false,
          false,
        );
      } else if (
        tipo_tributacao === 5 &&
        data.data?.uf?.toUpperCase() === 'SP'
      ) {
        val_icms = calculaIcms(
          val_tabela_liq,
          transformAsCurrency(nfItem?.per_aliq_icms_xml),
          transformAsCurrency(nfItem?.val_reducao_ent),
          2,
          false,
          false,
        );
      } else {
        val_icms = calculaIcms(
          val_tabela_liq,
          transformAsCurrency(nfItem?.per_aliq_icms_xml),
          transformAsCurrency(nfItem?.val_reducao_ent),
          0,
          false,
          false,
        );
      }

      let val_outros = 0;
      let val_isento = 0;
      if ([0, 1, 2, 8].includes(tipo_tributacao)) {
        val_outros =
          transformAsCurrency(val_tabela_liq) -
          transformAsCurrency(val_bc_icms);
      } else {
        val_isento = TruncaFrac(
          transformAsCurrency(val_tabela_liq) -
            transformAsCurrency(val_bc_icms),
          3,
        );
      }

      const flg_nao_pis_cofins =
        ncm.data?.data?.tipos[0]?.flg_nao_pis_cofins || false;

      const val_total_nf =
        transformAsCurrency(val_tabela_liq) +
        transformAsCurrency(nfItem.val_ipi) +
        transformAsCurrency(nfItem.val_icms_st_xml);

      const pisCofins = procedureCalculaPISCofins(
        flg_nao_pis_cofins,
        prod.data?.flg_nao_pis_cofins,
        fornecedor?.flg_nao_transf_pis_cofins,
        parametroLoja?.loja?.flg_st_bc_pis_cof,
        parametroLoja?.loja?.flg_ipi_bc_pis_cof,
        prod.data?.tipo_nao_pis_cofins,
        val_total_nf,
        // eslint-disable-next-line prettier/prettier
        transformAsCurrency(val_icms) + transformAsCurrency(val_bc_icms),
        0,
        0,
        0,
      );
      setCod_xml(item.nf.cod_xml);
      const NFItem = {
        uuid: nanoid(),
        num_item: nfItem.num_item,
        cfop: nfItem.cfop,
        cod_id_ctb: nfItem.cod_id_ctb,
        cod_produto: nfItem.cod_produto,
        des_produto: prod.data.des_produto,
        cod_gtin: prod.data?.cod_gtin_principal,
        cod_ncm: prod?.data.cod_ncm,
        num_ncm: ncm?.data?.data?.num_ncm,
        cod_tributacao,
        tipo_tributacao,
        per_aliq_icms: [7, 9].includes(perfilFinal.tipo_nf) ? 0 : val_icms,
        per_red_bc_icms: 0,
        per_icms,
        val_reducao: val_reducao_base_calculo,
        val_outros,
        cod_sit_tributacao,
        qtd_embalagem,
        des_embalagem: nfItem.des_unidade,
        qtd_entrada,
        qtd_total: qtd_embalagem * qtd_entrada,
        val_unitario: nfItem.val_unitario,
        val_total: qtd_entrada * nfItem.val_unitario,
        val_desconto: nfItem.val_desconto,
        val_despesa_acessoria: nfItem.val_despesa_acessoria,
        val_tabela_liq,
        val_ipi: nfItem.val_ipi,
        val_tabela_final,
        val_bc_icms,
        val_icms,
        val_isento,
        val_bc_st: nfItem.val_bc_st_xml,
        val_icms_st: nfItem.val_icms_st_xml,
        val_frete: nfItem.val_frete,
        isDisabled: false,
        isDisabledNF: true,
        flg_bonificado: nfItem.flg_bonificado,
        val_inputs: {
          produto: prod.data,
          tributacao,
          cfop: cfopFinal,
          ncm: ncm.data.data,
          // eslint-disable-next-line no-compare-neg-zero
          qtd_embalagem: qtd_embalagem === -0 ? 0 : qtd_embalagem,
          // eslint-disable-next-line no-compare-neg-zero
          qtd_entrada: qtd_entrada === -0 ? 0 : qtd_entrada,
          // eslint-disable-next-line no-compare-neg-zero
          val_unitario: nfItem.val_unitario === -0 ? 0 : nfItem.val_unitario,
          val_material: 0,
          val_desconto: nfItem.val_desconto,
          val_despesa_acessoria: nfItem.val_despesa_acessoria,
          val_ipi: nfItem.val_ipi,
          val_bc_st: val_bc_icms,
          val_icms_st: val_icms,
          val_frete: nfItem.val_frete,
          pis_cofins: pisCofins.val_pis,
          bonificado: nfItem.flg_bonificado,
        },
        ...pisCofins,
      };
      ItensNF.push(NFItem);
    }
    setItens(ItensNF);
    setLoading(false);
  }

  return (
    <NFOutrasOperacoesContext.Provider
      value={{
        codSeqNf,
        update,
        setUpdate,
        trasmissao,
        setTransmissao,
        setCodSeqNf,
        setNumChaveAcesso,
        showSearch,
        setShowSearch,
        loading,
        setLoading,
        onSubmit,
        onClearForm,
        onCancel,
        setItens,
        isOpenModalNovoItem,
        setIsOpenModalNovoItem,
        tributacaoNovoItem,
        setTributacaoNovoItem,
        ncmNovoItem,
        setNcmNovoItem,
        cfopNovoItem,
        setCfopNovoItem,
        resetModalItem,
        flg_nfe_pedente,
        setFlg_nfe_pedente,
        dtaEmissao,
        setDtaEmissao,
        dtaEntrada,
        setDtaEntrada,
        disable,
        setDisable,
        OpenModal,
        isOpenModalImportAjuste,
        setIsOpenModalImportAjuste,
        OpenModalAjuste,
        currentTab,
        setCurrentTab,
        loja,
        setLoja,
        buscarProduto,
        produto,
        fornecedor,
        setFornecedor,
        fornecedores,
        setFornecedores,
        perfil,
        setPerfil,
        perfis,
        setPerfis,
        serieSelect,
        setSerieSelect,
        serieList,
        setSeriesList,
        especieList,
        setEspecieList,
        /* Base NF * */
        setValue,
        errors,
        control,
        register,
        getValues,
        /* Modal * */
        handleSubmitModal,
        formStateModal,
        controlModal,
        registerModal,
        setValueModal,
        resetModal,
        /* Modal Ajuste* */
        formStateModalAjuste,
        controlModalAjuste,
        registerModalAjuste,
        setValueModalAjuste,
        resetModalAjuste,
        checkAllItens,
        checkItem,
        handleSubmitModalAjuste,
        loadingAjuste,
        setLoadingAjuste,
        total,
        setTotal,
        /*  Tranporte */
        formStateTransporte,
        controlTransporte,
        registerTransporte,
        setValueTransporte,
        resetTransporte,
        /*  Financeiro */
        formStateFinanceiro,
        controlFinanceiro,
        registerFinanceiro,
        setValueFinanceiro,
        resetFinanceiro,
        getValueFinanceiro,
        watchFinanceiro,
        /* Log */
        formStateLog,
        controlLog,
        registerLog,
        setValueLog,
        resetLog,

        finalizadoras,
        setFinalizadoras,
        condicoes,
        setCondicoes,
        itensFinaceiro,
        onClearFinaceiro,
        addItemFinaceiro,
        deleteItemFinaceiro,
        updateItemFinaceiro,
        updateFinaceiro,
        financeiroCalcular,
        codCondicao,
        setCodCondicao,
        numCondicao,
        setNumCondicao,

        tributacoes,
        setTributacoes,
        ncms,
        setNcms,
        cfops,
        setCfops,
        logs,
        setLogs,
        cod_xml,
        tipo_ajustes,
        setTipo_ajustes,
        init,
        setInit,
        getProdutos,
        itensAjuste,
        setItensAjuste,
        updateItemModal,
        onSubmitModal,
        itens,
        deleteItem,
        updateItem,
        numNF,
        setNumNF,
        importXML,
        setIsBlockNf,
        isBlockNf,
        formatPerfil,
        setItensFinaceiro,
        FocusFilter,
        clearErrors,
      }}
    >
      {children}
    </NFOutrasOperacoesContext.Provider>
  );
}

export const useNFOutrasOperacao = (): NFOutrasOperacoesContextData => {
  return useContext(NFOutrasOperacoesContext);
};
